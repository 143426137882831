<template>
  <div>
    <h5 class="title"><strong>Próximas Evaluaciones</strong></h5>
    <ul>
      <li
        v-for="evaluation in upcoming_evaluations"
        :key="evaluation.id"
        :title="evaluation.description"
      ><b-icon icon="clock"></b-icon>
        <router-link
          :to="{
            name: 'AnswerView',
            params: { evaluation_id: evaluation.id },
            force: true,
          }"
        >
          {{ evaluation.start_date | toDate }} - {{ evaluation.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "UpcomingEvaluations",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      upcoming_evaluations: [],
    };
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
    }),
  },
  methods: {
    fetchNextEvaluations() {
      if (!this.user) return;
      this.$restful.Post("/teaching/get_next_evaluations/").then((response) => {
        this.upcoming_evaluations = response.sort(function (a, b) {
          if (a.start_date > b.start_date) return 1;
          if (a.start_date < b.start_date) return -1;
          return 0;
        });
      });
    },
  },
  created() {
    this.fetchNextEvaluations();
  },
};
</script>

<style scoped>
ul li {
  margin-left: -1px;
  padding-left: .6rem;
  text-align: left;
  list-style-type: none;
  transition: 0.3s;
}
ul li:hover,
li a:hover {
  background-color: #fff;
  color: var(--secondary-color) !important;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.3s;
}
li a {
  color: var(--secondary-font-color);
  margin-left: .5rem;
  transition: 0.3s;
}
li:hover a {
  color: var(--secondary-color) !important;
}
.card .title {
  padding-bottom: 0.2rem;
  margin: 0 1rem;
  margin-bottom: 0.2rem !important;
  border-bottom: 1px solid var(--primary-hover-color);
}
</style>